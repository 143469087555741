
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { StackItem, VStack } from '@chakra-ui/react';
import { ProdClient } from 'client/client';
import { HeadComponent } from 'components/pages/Head';
import MainMetaHead from 'components/pages/MetaMainHead';
import { ISR_REVALIDATE_TIME } from 'lib/constants';
import cacheData from 'memory-cache';
import { Homepage as HomepageModel, MetatagItem } from 'models';
import { GetStaticProps } from 'next';
import React from 'react';
import { ContentType, mapper } from 'utils/componentMapper';
// import { appInsights } from '../appInsights';
interface HomepageProps {
    homepage: HomepageModel;
    metaTags: MetatagItem[];
}
const Homepage = ({ homepage, metaTags }: HomepageProps) => {
    // appInsights.trackTrace({ message: `Homepage codename - ${homepage.system.codename}` });
    cacheData.put('insight_theme', null);
    if (process.env.NEXT_PUBLIC_APP_ENV !== 'production')
        console.log(`This is a ${process.env.NEXT_PUBLIC_APP_ENV} deployment`);
    console.log(`Collection in use is ${process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION}`);
    return (<>
      {metaTags && metaTags.length > 0 && <MainMetaHead items={metaTags}/>}
      {homepage && <HeadComponent page={homepage}/>}

      <VStack>
        {homepage?.elements.content.linkedItems.map((element, index) => {
            const type = element.system.type as ContentType;
            return (<StackItem key={index} display='block'>
              {React.createElement(mapper(type) as any, { model: element })}
            </StackItem>);
        })}
      </VStack>
    </>);
};
export default Homepage;
const getStaticProps: GetStaticProps = async (ctx) => {
    const languageCodename = ctx.locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
    const homepage = await ProdClient.items<HomepageModel>()
        .type('homepage')
        .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
        .languageParameter(languageCodename)
        .depthParameter(2)
        .limitParameter(1)
        .toPromise()
        .then((response) => response.data.items[0])
        .catch(() => null);
    const metaTags = await ProdClient.items<MetatagItem>()
        .type('metatag_item')
        .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
        .languageParameter(languageCodename)
        .depthParameter(1)
        .toPromise()
        .then((response) => response.data.items)
        .catch(() => null);
    const variants = ctx.locales?.reduce((obj, item) => {
        obj[item] = '';
        return obj;
    }, {});
    return {
        props: { homepage: homepage || null, metaTags, variants } as HomepageProps,
        revalidate: ISR_REVALIDATE_TIME,
    };
};

    async function __Next_Translate__getStaticProps__195f55aa5bd__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195f55aa5bd__ as getStaticProps }
  